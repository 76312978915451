import Input, { Value, Country } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './PhoneInput.scss';

interface Props {
  value: string;
  country?: Country;
  international?: boolean;
  onChange: (value: string) => void;
  className?: string;
  name?: string;
  id?: string;
  error?: string;
  helperText?: string;
  label?: string;
}

const PhoneInput = ({
  country = 'US',
  international = false,
  value,
  onChange,
  className = '',
  label,
  name,
  id,
  error,
  helperText
}: Props) => {
  return (
    <div className={`CustomPhoneInput ${className}`}>
      {!!label && <div className='CustomPhoneInput__label mb-1'>{label}</div>}
      <div className='CustomPhoneInput__inputWrapper'>
        <Input
          withCountryCallingCode
          addInternationalOption={false}
          international={international}
          defaultCountry={country}
          value={value as Value}
          countries={['US', 'HN', 'AR', 'DO', 'UA', 'CL']}
          onChange={onChange}
        />
      </div>
      {helperText && (
        <div className='CustomPhoneInput__helperText'>{helperText}</div>
      )}
      {error && <div className='CustomPhoneInput__error'>{error}</div>}
    </div>
  );
};

export default PhoneInput;
