import './Select.scss';
import { SelectOption } from '../../core/types';
import { KeyboardEventHandler, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDebounce } from '../../hooks/useDebounce';
import { useOnClickOutside } from '../../hooks';
import { Scrollbars } from 'react-custom-scrollbars';
import { ReactComponent as PolygonIcon } from '../../icons/polygon.svg';
import TextField from '../TextField/TextField';

interface Props {
  options: SelectOption[];
  value: number | string;
  showSearchBar?: boolean;
  dropdownListMaxHeight?: number;
  onChange: (v: string | number) => void;
  onBlur?: () => void;
  placeholder?: string;
  className?: string;
  label?: string;
  error?: string;
  touched?: boolean;
  disabled?: boolean;
  isOpenAbove?: boolean;
}

const getActiveOption = (value: string | number, options: SelectOption[]) =>
  options.find((option) => value === option.id);

const Select = ({
  options,
  value,
  showSearchBar = false,
  placeholder = 'Please select',
  dropdownListMaxHeight = 300,
  onChange,
  onBlur,
  className = '',
  label,
  error,
  touched,
  disabled = false,
  isOpenAbove
}: Props) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [isOpened, setIsOpened] = useState(false);
  const [currentOption, setCurrentOptions] = useState<SelectOption[]>(options);
  const [searchText, setSearchText] = useState('');
  const checkedOption = getActiveOption(value, options);
  const debouncedIsOpened = useDebounce(isOpened, 200);
  const isDropdownOpened = isOpened || debouncedIsOpened;

  const content: string | JSX.Element = checkedOption
    ? checkedOption.content || checkedOption.name
    : placeholder;

  const onClickOuside = () => {
    if (!isOpened) return;
    setIsOpened(false);
    onBlur && onBlur();
  };

  useOnClickOutside(ref, onClickOuside);

  const onClick = () => {
    if (disabled) return;
    if (isOpened && onBlur) {
      onBlur();
    }
    setIsOpened(!isOpened);
  };

  const onSelect = (optionId: string | number) => () => {
    setIsOpened(false);
    onChange(optionId);
    setSearchText('');
    setCurrentOptions(options);
  };

  const onKeyDown: KeyboardEventHandler = (e) => {
    if (e.code === 'Escape' && isOpened) {
      onClick();
    }
  };

  const handleSearchItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value;
    setSearchText(searchQuery);

    if (searchQuery.length === 0) {
      return setCurrentOptions(options);
    }

    const searchResult = options.filter((option) =>
      option.name.toLowerCase().includes(searchQuery.trim().toLowerCase())
    );

    setCurrentOptions(searchResult);
  };

  return (
    <div className={`Select ${className}`}>
      {!!label && <div className='Select__label mb-1'>{label}</div>}
      <div
        ref={ref}
        tabIndex={0}
        className={classNames(
          'Select__input d-flex justify-content-between align-items-center',
          {
            'Select__input--active': isOpened,
            'Select__input--disabled': disabled
          }
        )}
        onKeyDown={onKeyDown}
      >
        <span
          className={classNames({
            Select__value: value !== -1
          })}
          onClick={onClick}
        >
          {content}
        </span>
        <PolygonIcon onClick={onClick} />
        {isDropdownOpened && (
          <div
            className={classNames('Select__dropdown', {
              fadeInScale: isOpened,
              fadeOutScale: !isOpened,
              'Select__dropdown--up': isOpenAbove
            })}
          >
            {showSearchBar && (
              <TextField
                className='Select__searchInput'
                placeholder='Type to search...'
                value={searchText}
                onChange={handleSearchItem}
              />
            )}

            <div className='Select__dropdownList' ref={dropdownRef}>
              <Scrollbars
                autoHeight
                autoHeightMin={25}
                autoHeightMax={dropdownListMaxHeight}
              >
                {currentOption.map((o) => (
                  <div
                    className='Select__dropdownItem'
                    onClick={onSelect(o.id)}
                    key={o.id}
                  >
                    {o.content || o.name}
                  </div>
                ))}
              </Scrollbars>
            </div>
          </div>
        )}
      </div>
      {error && <div className='Select__error'>{error}</div>}
    </div>
  );
};

export default Select;
